<template>
  <div class="closing main-content">
    <div class="crumbs">
      <ul>
        <li><a href="#" @click="$router.push('/')">Home</a></li>
        <li>Checkout</li>
      </ul>
    </div>
    <div class="main">
      <h2 class="cart-title">
        <div class="_ico"><img src="@/assets/image/cart/closing-title.png" alt="購物車"></div><span>Checkout</span>
      </h2>
      <div class="cart-box">
        <div class="row">
          <div class="col _left">
            <div class="order-box">
              <div class="_title">Your order</div>
              <div class="_info">Order number：{{ orderobj.order_number }}</div>
              <div class="_info-box">
                <div class="_title">Product：</div>
                <div class="_item" v-for="(item, i) in orderobj.plan_list" :key="i">
                  <div class="_txt">{{ item.name }}</div>
                  <div class="_box cus-box">
                    <div class="_txt">{{ item.days + ' Days'}} {{ item.spec }}<small v-if="item.package && item.package.includes('By Day')">/ Day</small> x {{ item.num }}</div>
                    <div class="_txt">USD $ {{ item.special_amount.toLocaleString() }}</div>
                  </div>

                </div>
                <div class="_code">
                  <div class="_txt">Discount code</div>
                  <el-input placeholder="Enter code" v-model="orderobj.promo_code" :disabled="codeDisabled" class="input-code">
                    <button slot="append" type="button" class="btn btn-default" :disabled="codeDisabled" @click="getcode">Use</button>
                  </el-input>
                </div>
                <div class="_total">
                  <div class="_box">
                    <div class="_txt">Subtotal</div>
                    <div class="_txt">USD $ {{ orderobj.discount_amount?orderobj.discount_amount.toLocaleString():orderobj.discount_amount }}</div>
                  </div>
                  <div class="_box">
                    <div class="_txt">Discount</div>
                    <div class="_txt">-USD $ {{discount?discount.toLocaleString():discount }}</div>
                  </div>
                  <div class="_box">
                    <div class="_txt">Total</div>
                    <div class="_txt total">USD $ <span class="_total">{{ Number(orderobj.discount_amountTwo).toLocaleString('en-US',{minimumFractionDigits:2,maximumFractionDigits:2}) }}</span></div>
                  </div>
                </div>
                <div class="tip">
                  <p><small>Due to the settlement in Taiwanese dollars, <br />USD {{ Number(orderobj.discount_amountTwo).toLocaleString('en-US',{minimumFractionDigits:2,maximumFractionDigits:2}) }} will be charged  TWD {{ (orderobj.discount_amountTwo*rate).toLocaleString('en-US',{minimumFractionDigits:2,maximumFractionDigits:2}) }} </small></p>
                  <p><small><a href="https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=TWD" target="_blank">Click to check online exchange rate</a></small></p>
                </div>
                <div class="_message">
                  <el-input type="textarea" :rows="4" placeholder="Message" v-model="orderobj.remark">
                  </el-input>

                </div>
              </div>
            </div>
          </div>
          <div class="col _right">
            <div class="closing-box">

              <div class="fp-tabs">
                <div class="_item _label">Please fill your information</div>
              </div>
              <div class="fp-body">
                <div class="fp-item">
                  <div class="_item"><el-input v-model="fristName" placeholder="First name"></el-input></div>
                  <div class="_item"><el-input v-model="lastName" placeholder="Last Name"></el-input>
                  </div>
                </div>
                <div class="fp-item">
                  <div class="_item" style="margin-bottom: 10px;flex-basis: 50%;flex-grow: 0;"><el-input v-model="email" :disabled="isshow" type="email"
                                                                            placeholder="Your email"></el-input></div>
                  <div class="_item" style="margin-bottom: 10px;"><el-input v-model="reEmail" :disabled="isshow" type="email"
                                                                            placeholder="Re-enter email"></el-input></div>
                </div>
                <span style="margin-bottom: 25px;display: inline-block;margin-left: 8px;font-size: 14px;
        line-height: 20px;color: #5CE9C7;">The email  will  receive the eSIM you are purchasing </span>

                <div class="select-country">
                  <div class="_select" style="flex-basis: 50%;" >
                    <div class="_selectImg"><span class="_flag" :style="'background-position:'+countryImg +';height:'+height"></span></div>
                    <el-select popper-class="cus-select" no-match-text="No corresponding country found" v-model="countryIndex" filterable placeholder="請選擇" @change="showSelectedImage(countryIndex)">
                      <el-option
                          v-for="(item,i) in country"
                          :key="i"
                          :label="item.name"
                          :value="item.value">
                        <span style="float: left"><span class="_flag" :style="'background-position:'+item.pos+';height:'+item.height"></span></span>
                        <span class="flagName">{{item.name}}</span>
                        <span style="float: right; font-size: 13px">+{{ item.code }}</span>
                      </el-option>
                    </el-select>
                  </div>
                  <div class="_input" style="flex-basis: 50%;" ><el-input v-model="phoneNumber" placeholder="Phone number"></el-input></div>
                </div>

                <div class="item-title">
                  <div class="_label">Credit card Information</div>
                  <img src="../../assets/image/cart/card-type.png" />
                </div>
                <div class="form-item">
                  <div class="tpfield" id="card-number"></div>
                  <el-input prefix-icon="ico-cart" class="icon-custom" disabled	 v-model="value" placeholder=""></el-input>
                </div>
                <div class="row form-item col-2">
                  <div class="col _item">
                    <div class="_label">Expiration Date</div>
                    <div class="btItem">
                      <div class="btItem-nox" id="card-expiration-date"></div>
                      <el-input v-model="value" disabled placeholder=""></el-input>
                    </div>
                  </div>
                  <div class="col _item">
                    <div class="_label">CVV</div>
                    <div class="btItem">
                      <div class="btItem-nox" id="card-ccv"></div>
                      <el-input v-model="value" disabled placeholder=""></el-input>
                    </div>
                  </div>
                </div>

              </div>
              <div class="fukuan">
                <div class="form-item">
                  <div class="row ">
                    <div class="col form-item-btn">
                      <button type="button" class="btn btn-primary btn-block"  :disabled="btnActive" @click="goTO">Confirm Payment</button>
                    </div>
                  </div>
                </div>
                <div class="form-item">
                  <div class="row">
                    <div class="col form-item-txt text-center">
                      Fully certified for PCIDSS Level 1 security across all environments.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!--      <div>-->
    <!--        <label>CardView</label>-->
    <!--        <div id="cardview-container"></div>-->
    <!--      </div>-->
<!--    //測試用，正式上線需刪除-->
    <div class="ui grid centered doubling stackable" style="display: none;">
      <div class="six wide column">
        <div class="ui segment">
          <h1 class="ui header">Direct Pay - iframe</h1>

          <form class="ui form">
            <div class="field">
              <label>信用卡</label>
              <!--                <div id="tappay-iframe"></div>-->
            </div>
          </form>

          <br>
          <div class="ui button" id="submit">Get Prime</div>
          <br>
          <pre class="ui error message" id="message" style="overflow-x: auto">
                </pre>
          <pre class="ui info message" id="result" style="overflow-x: auto">
                </pre>
          <pre class="ui info message" id="curl" style="overflow-x: auto">
                </pre>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import '../../utils/tappay.js'
import {codeCheck, orderDetails, toPay, updatePreOrder,toTaPay,getCurrentRate} from "../../request/api";
import {country} from "./data";

export default {
  name: 'Closing',
  data() {
    return {
      num: 1,
      value: '',
      code: 'TX8767',
      phoneNumber:'',
      btnActive:true,
      countryImg: '-5241px 0',
      height:'11px',
      countryIndex:1,
      country: country,
      receipt: 1,
      question: false,
      codeDisabled: false,// 優惠碼禁止點擊
      form: {},
      options: [
        {
          value: 1,
          label: '選項1'
        },
        {
          value: 2,
          label: '選項2'
        }
      ],
      tagList: ['# 美國', '# 本地卡', '# 按日計價'],

      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '使用方法'
        },
      ],
      orderobj: {},
      discount: 0,
      fristName: '',
      email: '',
      phone: '',
      lastName:'',
      reEmail: '',
      isshow:false,
      iniTP:{},
      prime:'',
      phone_number:'',
      rate:0,// 匯率
    }
  },
  created() {
    this.getCurrentRate()
    this.getOrderDetails()
  },

  mounted() {
    Array.from(document.getElementsByClassName("el-select")).forEach(item => {
      item.children[0].children[0].removeAttribute("readOnly");
      item.children[0].children[0].onblur = function() {
        let _this = this;
        setTimeout(() => {
          _this.removeAttribute("readOnly");
        }, 200);
      };
    });
    TPDirect.setupSDK(132722, 'app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ', 'sandbox');
    // TPDirect.setupSDK(132722, 'app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ', 'production');
    //測試用，正式上線需刪除
    var statusTable = {
      '0': '欄位已填好，並且沒有問題',
      '1': '欄位還沒有填寫',
      '2': '欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor',
      '3': '使用者正在輸入中',
    }
    let _this = this
    let fields = {
      number: {
        // css selector
        // element: '#card-number',
        element: document.getElementById('card-number'),
        placeholder: 'Please enter card number'
      },
      expirationDate: {
        // DOM object
        element: document.getElementById('card-expiration-date'),
        placeholder: 'MM/YY'
      },
      ccv: {
        element: '#card-ccv',
        placeholder: 'ccv'
      }
    }
    var config = {
      isUsedCcv: false,
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    }
    // TPDirect.card.setup(fields, defaultCardViewStyle, config)

    TPDirect.card.setup({
      fields: fields,
      styles: {
        // Style all elements
        'input': {
          'color': '#ffffff'
        },
        // Styling ccv field
        'input.ccv': {
          // 'font-size': '16px'
        },
        // Styling expiration-date field
        'input.expiration-date': {
          // 'font-size': '16px'
        },
        // Styling card-number field
        'input.card-number': {
          // 'font-size': '16px'
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          'color': 'green'
        },
        // style invalid state
        '.invalid': {
          'color': 'red'
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          'input': {
            'color': 'orange'
          }
        }
      },
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    })
    TPDirect.card.onUpdate(function (update) {
      //測試用，正式上線需刪除
      // var submitButton = document.querySelector('#submit')
      //
      // if (update.canGetPrime) {
      //   submitButton.removeAttribute('disabled')
      // } else {
      //   submitButton.setAttribute('disabled', true)
      // }
      //
      // var message = document.querySelector('#message')
      //測試用，正式上線需刪除
      message.innerHTML = `
                canGetPrime: ${update.canGetPrime} \n
                cardNumberStatus: ${statusTable[update.status.number]} \n
                cardExpiryStatus: ${statusTable[update.status.expiry]} \n
                ccvStatus: ${statusTable[update.status.ccv]}
            `.replace(/    /g, '')

      if (update.hasError) {
        console.log('error',update)
        _this.btnActive = true
      } else {
        console.log('info',update)
        _this.btnActive = false
      }
    })
    // document.querySelector('#submit').addEventListener('click', function(event) {
    //   TPDirect.card.getPrime(function(result) {
    //     if (result.status !== 0) {
    //       console.error('getPrime error')
    //     }
    //     var prime = result.card.prime
    //     _this.prime = prime
    //     console.log('getPrime success: ' + _this.prime)
    //   })
    // })
  },

  methods: {
    send_to_page() {
      // console.log(this.orderobj.plan_list)
     let items =  this.orderobj.plan_list.map(v =>{
        return {
          item_id:v.id,
          item_name:v.name,
          item_category:v.days+'Days',
          item_category2:v.spec,
          item_category3:v.package,
          quantity:v.num,
          price: v.special_amount
        }
      })
      let event_config = {
        currency: "USD",
        value: Number(this.orderobj.discount_amountTwo).toFixed(2),
        items: items
      }
      console.log(event_config)
      this.$gtag.event('begin_to_check_out', event_config);
    },
    showSelectedImage(id){
      console.log(id)
      const img = this.country.filter(v => {
        if(v.value === id){
          return true
        }

      })
      this.countryImg = img[0].pos
      this.height = img[0].height
      this.phone_number = '+' + img[0].code
    },
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num += 1
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },
    //去付款
    async goTO() {
      // this.$router.push({
      //   path: '/paymentResult',
      //   query: {}
      // })

      if (this.fristName&& this.email && this.reEmail || this.lastName) {
        if (this.email == this.reEmail) {
          let obj = {
            order_id: this.$route.query.id,
            promo_code: this.orderobj.promo_code,
            name: this.fristName,
            email: this.email,
            lastName: this.lastName,
            remark: this.orderobj.remark,
          }
          let res = await updatePreOrder(obj);
          if (res.code == 200) {
            window.sessionStorage.removeItem('shopCart')
            this.$store.commit('changgetCart')
            // this.getLink()
            this.getPrim()
          } else {
            this.$message.warning(res.msg)
          }
        } else {
          this.$message.warning('The two mailboxes are inconsistent')
        }
      } else if (
          !this.fristName
      ) {
        this.$message.warning('Please enter your name')
      } else if (
          !this.reEmail
      ) {
        this.$message.warning('Please enter the confirmation mailbox')
      }


    },
    // 新的付款
    async getPrim(){
      let _this =this
        TPDirect.card.getPrime(function(result) {
          console.log('result',result)
          if (result.status !== 0) {
            console.error('getPrime error')
          }
          var prime = result.card.prime
          _this.prime = prime
          console.log('getPrime success: ' + _this.prime)
          _this.goToPay()
        })
    },
    async goToPay(){
      if(this.prime != ''){
        let data = {
          order_id: this.$route.query.id,
          prime:this.prime,
          name:this.fristName,
          email:this.email,
          lastName: this.lastName,
          phone_number: this.phone_number + this.phoneNumber
        }
        let res = await toTaPay(data);
        if (res.code == 200) {
          console.log('res',res)
          let items =  this.orderobj.plan_list.map(v =>{
            return {
              item_id:v.id,
              item_name:v.name,
              item_category:v.days+'Days',
              item_category2:v.spec,
              item_category3:v.package,
              quantity:v.num,
              price: v.special_amount
            }
          })
          let event_config = {
            currency: "USD",
            coupon:this.orderobj.promo_code,
            value: Number(this.orderobj.discount_amountTwo).toFixed(2),
            items: items
          }
          console.log(event_config)
          this.$gtag.event('confirm_Payment', event_config);
          window.location.href = res.data.payment_url
          // this.$message.success(res.msg)
          // this.$router.push({
          //   path: '/paymentResult',
          //   query: {
          //     success: 1,
          //     user_email:this.email
          //   }
          // })
        } else {
          console.log('res2',res)
          // this.$message.warning(res.msg)
          // this.$router.push({
          //   path: '/paymentResult',
          //   query: {}
          // })
        }
      }
    },
    //獲取付款頁面
    async getLink() {
      let data = {
        order_id: this.$route.query.id,
      }
      let res = await toPay(data);
      if (res.code == 200) {
        window.location = res.data.pay_url
      } else {
        this.$message.warning(res.msg)
      }
    },


    //獲取訂單詳情
    async getOrderDetails() {
      let data = {
        order_id: this.$route.query.id
      }
      let res = await orderDetails(data);
      if (res.code == 1) {
        this.orderobj = res.data;
        this.orderobj.discount_amountTwo = res.data.discount_amount;
        if (res.data.promo_code) {
          this.getcode()
        }
        if(window.localStorage.getItem('TOKEN_NAME')){
          this.isshow = true
        }else{
          this.isshow = false
        }
        // this.fristName = res.data.user_name
        this.email = res.data.user_email
        this.phone = res.data.user_phone,
            this.reEmail = res.data.user_email
        this.send_to_page();
      } else {
        this.$message.warning(res.msg)
      }
    },

    //使用折扣碼
    async getcode() {
      if (this.orderobj.promo_code) {
        let data = {
          promo_code: this.orderobj.promo_code
        }
        let res = await codeCheck(data)
        if (res.code == 1) {
          if(this.discount){
            this.orderobj.discount_amountTwo = this.orderobj.discount_amountTwo + this.discount;
          }

          this.discount = 0;
          // let price = Number(this.orderobj.discount_amountTwo) * (Number(res.data.discount) / 100)
          // 有優惠用原價總價計算
          // let price = Number(this.orderobj.total_amount) * (Number(res.data.discount) / 100)
          // this.discount = Math.round(Number(this.discount) + Number(price)).toFixed(1);
          // this.discount = (Number(this.discount) + Number(price)).toFixed(2);
          this.discount =  (Number(this.orderobj.discount_amountTwo) * Number(res.data.discount)/100).toFixed(2)
          // this.orderobj.discount_amountTwo = Number(this.orderobj.discount_amountTwo) - Number(price);
          this.orderobj.discount_amountTwo = (Number(this.orderobj.discount_amountTwo) - Number(this.discount)).toFixed(2) > 0?(Number(this.orderobj.discount_amountTwo) - Number(this.discount)).toFixed(2):0;
          this.codeDisabled = true
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } else {
        this.discount = 0;
        this.orderobj.discount_amountTwo = this.orderobj.discount_amount;
      }
    },
    async getCurrentRate() {
      const res = await getCurrentRate({})
      this.rate = Number(res.data.rate)
    }
  }
}
</script>

<style lang="less" scoped>
.closing .cart-box ._left {
  max-width: 500px;
}

.closing-box {
  width: 100%;
}

._code {
  display: flex;
  align-items: center;

  ._txt {
    flex: 1;
  }

  .input-code {
    flex: 1;
  }
}

.closing-box {
  position: relative;
  height: 550px;
}

.fukuan {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

._message {
  display: flex;
  white-space: nowrap;
}

.el-textarea {
  // margin-left: 10px;
}
::v-deep .el-textarea .el-textarea__inner{
  background: transparent;
  border-color: transparent;
  padding: 0;
}
.tip{
  color: #5CE9C7;
  font-size: 12px;
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}
.tip p{
  color: #5CE9C7;
}
.tip a{
  color: #00A2FD;
}
@media screen and (max-width: 800px){
  .closing .order-box ._info-box ._code{
    flex-flow: column;
    align-items: start;
  }
  .closing .order-box ._info-box ._box.cus-box{flex-wrap: wrap;}
  .closing .order-box ._info-box ._box.cus-box ._txt:first-child{width: auto;}
  ::v-deep .input-code .el-input__inner{height: 40px;line-height: 40px;}
}

</style>
